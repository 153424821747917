import React from "react";
import "./App.css";

export const currentAndroidVersion = "4.00 (141)";
export const currentIosVersion = "4.00 (141)";
export const currentWebVersion = "sPRODw41";

const notes = `
- New Lokate ecosystem with Site, Driver and Transport Planner.
- A new Transport Planner website for supplier.
- updates to driver UI allowing them to scan route.
- Discontinuation of old supplier website.
- Bug fixes.
`;

const ReleaseNotes = () => {
  return (
    <section className="App">
      <main className="App-main">
        <header className="App-header">
          <img src={`../img/lokate-light-logo.svg`} height={40} />
        </header>
        <section className="Container">
          <div className="released-version-wrapper">
            <div className="VersionTextContent">
              android version: {currentAndroidVersion}
            </div>
            <div className="VersionTextContent">
              ios version: {currentIosVersion}
            </div>
            <div className="VersionTextContent">
              web version: {currentWebVersion}
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
              fontWeight: "bold",
              color: "darkslategray",
            }}
          >
            Latest Release notes:
          </div>
          <br></br>
          {notes.split("- ").map((line, i) => (
            <div
              style={{
                textAlign: "left",
                paddingLeft: "20px",
                paddingBottom: "5px",
                color: "darkslategray",
              }}
              key={"note_" + i}
            >
              {i != 0 && "- "}
              {line}
            </div>
          ))}
        </section>
      </main>
    </section>
  );
};

export default ReleaseNotes;
